import React from 'react';
import { useStyles } from '@wix/tpa-settings/react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { Text } from 'wix-ui-tpa/cssVars';

import { IMAGE_ROUTE } from '../../../constants/image-route';
import stylesParams, { Alignment, PageLayout, StepThumbnail } from '../stylesParams';
import { style, classes, vars } from './details-card.st.css';
import { getShadowOffsets } from '../../../utils/get-shadow-angles';

type DetailsItem = {
  title?: string;
  description: string;
};

interface DetailsCardProps {
  step: number;
  title: string;
  details: DetailsItem[];
  iconPath: string;
  dataHook?: string;
  showTiers: boolean;
}

const alignmentToStateMap = {
  [Alignment.Left]: 'left',
  [Alignment.Center]: 'center',
  [Alignment.Right]: 'right',
};

const layoutToStateMap = {
  [PageLayout.Grid]: 'grid',
  [PageLayout.List]: 'list',
  [PageLayout.Side]: 'side',
};

export const DetailsCard: React.FC<DetailsCardProps> = ({ step, title, details, iconPath, dataHook, showTiers }) => {
  const styles = useStyles();
  const { isMobile, isRTL } = useEnvironment();
  const { t } = useTranslation();
  const showStepIndication = styles.get(stylesParams.showStepIndication);
  const shadowOffsets = getShadowOffsets({
    angle: styles.get(stylesParams.detailsShadowAngle),
    distance: styles.get(stylesParams.detailsShadowDistance),
  });
  const showIcon = styles.get(stylesParams.cardsStepThumbnail) === StepThumbnail.Icon;
  const pageLayout = isMobile ? PageLayout.Grid : (styles.get(stylesParams.pageLayout) as PageLayout);
  const alignment = isMobile ? Alignment.Left : (styles.get(stylesParams.cardsContentAlignment) as Alignment);

  const renderDetails = () => {
    if (showTiers) {
      return (
        <div className={classes.details}>
          {details.map((detail, index) => {
            return (
              <div key={index} className={classes.detail}>
                {detail.title ? (
                  <Text tagName="p" className={classes.detailTitle}>
                    {detail.title}
                  </Text>
                ) : null}
                <Text tagName="p" className={classes.detailDescription} data-hook={`${dataHook}-description-${index}`}>
                  {detail.description}
                </Text>
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <ul className={classes.details}>
        {details.map((detail, index) => {
          return (
            <li key={index} aria-label={t('app.role')} className={classes.detail}>
              {detail.title ? (
                <Text tagName="p" className={classes.detailTitle}>
                  {detail.title}
                </Text>
              ) : null}
              <Text tagName="p" className={classes.detailDescription} data-hook={`${dataHook}-description-${index}`}>
                {detail.description}
              </Text>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <li
      data-hook={dataHook}
      className={style(classes.root, {
        alignment: alignmentToStateMap[alignment],
        pageLayout: layoutToStateMap[pageLayout],
        isMobile,
        showShadow: styles.get(stylesParams.detailsShowShadow),
        isRTL,
      })}
      style={{ [vars.shadowXOffset]: shadowOffsets.xOffset + 'px', [vars.shadowYOffset]: shadowOffsets.yOffset + 'px' }}
    >
      <div className={classes.header}>
        {showStepIndication ? (
          showIcon ? (
            <img aria-hidden alt={t('app.image.program-step-icon')} src={IMAGE_ROUTE(iconPath)} />
          ) : (
            <Text className={classes.step}>{`0${step}`}</Text>
          )
        ) : null}
        <Text tagName="h2" className={classes.headerTitle} data-hook={`${dataHook}-title`}>
          {title}
        </Text>
      </div>
      {renderDetails()}
    </li>
  );
};
