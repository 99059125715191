import React from 'react';
import { Text, Divider } from 'wix-ui-tpa/cssVars';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { TiersProgramSettings } from '@wix/ambassador-loyalty-v1-tier/types';

import { IMAGE_ROUTE } from '../../../constants/image-route';
import { SimpleReward, SimpleRule, SimpleTier } from '../../../types/domain';
import { classes, style, vars } from './tier-cards.st.css';
import settingsParams from '../settingsParams';
import { alignmentToStateMap } from '../../../utils/alignment-to-state-map';
import stylesParams, { Alignment, BulletStyle, PointsTextPosition, TiersLayout } from '../stylesParams';
import { BulletThumbnail } from './bullet-thumbnail';
import { getShadowOffsets } from '../../../utils/get-shadow-angles';

interface TierCardsProps {
  simpleTiers: SimpleTier[];
  simpleRules: SimpleRule[];
  simpleRewards: SimpleReward[];
  cardsPerRow: number;
  cardsCount: number;
  layout: TiersLayout;
  tiersProgramSettings: TiersProgramSettings;
}

type DetailsItem = {
  title: string;
  description: string;
};

const layoutToStateMap = {
  [TiersLayout.Cards]: 'cards',
  [TiersLayout.List]: 'list',
};

const positionToStateMap = {
  [PointsTextPosition.Above_Title]: 'above_title',
  [PointsTextPosition.Under_Title]: 'under_title',
  [PointsTextPosition.Under_Description]: 'under_description',
};

export const TierCards: React.FC<TierCardsProps> = ({
  simpleTiers,
  simpleRules,
  simpleRewards,
  cardsPerRow,
  cardsCount,
  layout,
  tiersProgramSettings,
}) => {
  const settings = useSettings();
  const styles = useStyles();
  const { t } = useTranslation();
  const { isMobile, isRTL } = useEnvironment();
  const alignment = styles.get(stylesParams.tiersContentAlignment) as Alignment;
  const showTierIcon = styles.get(stylesParams.tiersShowIcon) as Boolean;
  const showTierDescription = styles.get(stylesParams.tiersShowDescription) as Boolean;
  const pointsTextPosition = styles.get(stylesParams.tiersPointsTextPosition) as PointsTextPosition;
  const bulletThumbnailStyle = styles.get(stylesParams.tiersBulletsStyle) as BulletStyle;
  const shadowOffsets = getShadowOffsets({
    angle: styles.get(stylesParams.tiersShadowAngle),
    distance: styles.get(stylesParams.tiersShadowDistance),
  });

  const baseTier: SimpleTier = {
    title: tiersProgramSettings.baseTierDefinition?.name ?? '',
    description: tiersProgramSettings.baseTierDefinition?.description ?? '',
    iconUrl: tiersProgramSettings.baseTierDefinition?.icon?.url ?? '',
  };

  const renderDetails = (details: DetailsItem[]) =>
    details.map((detail, index) => (
      <div key={index} className={classes.detailContainer}>
        {alignment !== Alignment.Right ? (
          bulletThumbnailStyle === BulletStyle.None ? null : (
            <div className={classes.detailBulletContainer}>
              <BulletThumbnail type={bulletThumbnailStyle} size={17} />
            </div>
          )
        ) : null}
        <li aria-label={t('app.tiers.role')} className={classes.detailTextContainer}>
          <Text className={classes.detailTitle}>{detail.title}</Text>
          <Text className={classes.detailDescription}>{detail.description}</Text>
        </li>
        {alignment === Alignment.Right ? (
          bulletThumbnailStyle === BulletStyle.None ? null : (
            <div className={classes.detailBulletContainer}>
              <BulletThumbnail type={bulletThumbnailStyle} size={17} />
            </div>
          )
        ) : null}
      </div>
    ));

  const renderRules = (tierId: string | undefined | null) => {
    const rules = simpleRules.filter((rule) => rule.tierId === tierId || (!rule.tierId && !tierId));

    if (!rules.length) {
      return null;
    }

    return (
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{settings.get(settingsParams.earnPointsTitle)}</Text>
        <ul>{renderDetails(rules)}</ul>
      </div>
    );
  };

  const renderRewards = (tierId: string | undefined | null) => {
    const rewards = simpleRewards.filter((reward) => reward.tierId === tierId || (!reward.tierId && !tierId));

    if (!rewards.length) {
      return null;
    }

    return (
      <div className={classes.section}>
        <Text className={classes.sectionTitle}>{settings.get(settingsParams.redeemPointsTitle)}</Text>
        <ul>{renderDetails(rewards)}</ul>
      </div>
    );
  };

  const renderTierCards = () => {
    const cards = [baseTier, ...simpleTiers].map((tier, index) => (
      <li className={classes.tierCard} key={index}>
        <div
          className={style(classes.tierCardHeader, {
            pointsTextPosition: positionToStateMap[pointsTextPosition],
          })}
        >
          <div className={classes.tierCardTitleA11y}>
            <div className={classes.tierCardTitleContainer}>
              {alignment === Alignment.Left || alignment === Alignment.Center ? (
                <>
                  {showTierIcon && tier.iconUrl ? (
                    <img
                      alt={t('app.image.tier-icon')}
                      aria-hidden
                      className={classes.tierCardIcon}
                      src={IMAGE_ROUTE(tier.iconUrl)}
                    />
                  ) : null}
                </>
              ) : null}
              <Text tagName="h3" className={classes.tierCardTitle}>
                {tier.title}
              </Text>
              {alignment === Alignment.Right ? (
                <>
                  {showTierIcon && tier.iconUrl ? (
                    <img
                      alt={t('app.image.tier-icon')}
                      aria-hidden
                      className={classes.tierCardIcon}
                      src={IMAGE_ROUTE(tier.iconUrl)}
                    />
                  ) : null}
                </>
              ) : null}
            </div>
            {pointsTextPosition === PointsTextPosition.Above_Title ? (
              <Text className={classes.tierCardPoints}>
                {t('app.tiers.points-needed', { requiredPoints: tier.requiredPoints ?? 0 })}
              </Text>
            ) : null}
          </div>
          {pointsTextPosition === PointsTextPosition.Under_Title ? (
            <Text className={classes.tierCardPoints}>
              {t('app.tiers.points-needed', { requiredPoints: tier.requiredPoints ?? 0 })}
            </Text>
          ) : null}
          {showTierDescription ? (
            <>
              {tier.description.trim() ? <Text className={classes.tierCardDescription}>{tier.description}</Text> : null}
              {pointsTextPosition === PointsTextPosition.Under_Description ? (
                <Text className={classes.tierCardPoints}>
                  {t('app.tiers.points-needed', { requiredPoints: tier.requiredPoints ?? 0 })}
                </Text>
              ) : null}
            </>
          ) : null}
        </div>
        <Divider className={classes.tierCardDivider} />
        <div className={classes.sectionsContainer}>
          {renderRules(tier.id)}
          {renderRewards(tier.id)}
        </div>
      </li>
    ));

    if (cardsCount > cards.length) {
      const moreCards = [...cards];
      for (let i = cards.length; i < cardsCount; i++) {
        moreCards.push(cards[cards.length - 1]);
      }
      return moreCards;
    } else if (cardsCount < cards.length) {
      return cards.slice(0, cardsCount);
    }
    return cards;
  };

  const tierCards = renderTierCards();

  return (
    <ol
      className={style(classes.root, {
        cardsPerRow,
        lastCardsCount: tierCards.length % cardsPerRow,
        alignment: alignmentToStateMap[alignment],
        showShadow: styles.get(stylesParams.tiersShowShadow),
        layout: layoutToStateMap[layout],
        isMobile,
        isRTL,
      })}
      style={{
        [vars.shadowXOffset]: shadowOffsets.xOffset + 'px',
        [vars.shadowYOffset]: shadowOffsets.yOffset + 'px',
      }}
    >
      {tierCards}
    </ol>
  );
};
