import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Text } from 'wix-ui-tpa/cssVars';

import { Content } from './content';
import { classes } from './empty-state.st.css';
import { DataHook } from '../../../types/data-hook';

export const EmptyState = () => {
  const { t } = useTranslation();
  return (
    <Content>
      <Text data-hook={DataHook.EmptyState} className={classes.text}>
        {t('app.empty-state.program-not-availabe')}
      </Text>
    </Content>
  );
};
