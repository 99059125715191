import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Text } from 'wix-ui-tpa/cssVars';

import { Content } from './content';
import { classes } from './error-state.st.css';
import { DataHook } from '../../../types/data-hook';

export const ErrorState = () => {
  const { t } = useTranslation();
  return (
    <Content>
      <div data-hook={DataHook.ErrorState}>
        <Text tagName="h3" data-hook={DataHook.ErrorStateTitle} className={classes.title}>
          {t('app.error-state.service-not-available')}
        </Text>
        <Text tagName="p" data-hook={DataHook.ErrorStateDescription} className={classes.description}>
          {t('app.error-state.reload-page')}
        </Text>
      </div>
    </Content>
  );
};
